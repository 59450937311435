/*コーディング時には、url(../)と階層をひとつ上げることを忘れない*/
.cd-bg-1 {
  background: url(../images/bg01.jpg)no-repeat top center;
  background-size: cover;
}

.cd-bg-2 {
  background: url(../images/bg02.jpg)no-repeat bottom center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

@media screen and (max-width: 768px) {
  .content-pc-title {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .content-sp-title {
    display: none;
  }
}

/*メッセージ上*/
.home-about-block{
  display:flex;
  align-items: flex-end;
  padding: 85px;
  @media screen and (max-width: 992px) {
    display: block;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

.home-about-content{
  margin-right: 25px;
  @media screen and (max-width: 992px) {
    margin-right: 0;
  }
}

.home-about-title{
  margin: 0 25px 0 0;
  font-size: 36px;
  font-weight: 400;
  color: #1f518e;
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
    line-height: 1.3;
  }
  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
}

.home-about-text{
  font-size: 16px;
  line-height: 2;
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
    br{
      display:none;
    }
  }
}

/*メッセージ下*/

.home-catch{
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
}

.home-catch-inner{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 940px;
  margin: 0 auto 60px;
  padding: 0 10px;
  @media screen and (max-width: 992px) {
    display: block;
    figure{
      display:none;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.home-catch-title{
  margin: 0 0 30px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  strong{
    font-size: 34px;
    color:#ead130;
    vertical-align: baseline;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
}

.home-catch-text{
  span{
    padding: 2px 0;
    background: #ead130;
    font-weight: 700;
    font-family:" メイリオ",Meiryo;
    color: #1f518e;
  }
}

.home-catch-title-bg{
  margin-bottom: 2px;
  display: inline-block;
  padding: 0 10px;
  background: #1f518e;
  line-height: 1.5;
  vertical-align: bottom;
  @media screen and (max-width: 992px) {
    display:block;
    margin-bottom: 0;
  }
}

.home-catch-text{
  font-size: 16px;
}

.home-catch-strength{
  margin-bottom: 50px;
  padding: 30px 30px 40px;
  background: rgba(74, 75, 75,0.18);
  color: #ffffff;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    padding: 15px 30px;
  }
}

.home-catch-strength-list{
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.home-catch-strength-title{
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 992px) {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.home-catch-strength-list{
  li{
    margin-bottom: 20px;
    font-size: 18px;
    font-family:'Noto Sans JP';
    line-height: 1.2;
    padding: 5px 0 15px 80px;
    span{
      font-size: 24px;
      font-weight: 700;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      padding: 0 0 15px 60px;
      font-size: 16px;
      span{
        font-size: 18px;
      }
    }
  }
  .one{
    background: url(../images/icon-home-tel.png)no-repeat;
    @media screen and (max-width: 768px) {
      background-size: 50px auto;
    }
  }
  .two{
    padding: 5px 0 15px 94px;
    border-left: 1px solid #eff3f9;
    background: url(../images/icon-home-money.png) no-repeat left 14px center;
    @media screen and (max-width: 992px) {
      border: 0;
      background-position: left 0;
      padding-left: 80px;
    }
    @media screen and (max-width: 768px) {
      background-size: 50px auto;
      padding-left: 60px;
    }
  }
  .three{
    padding: 5px 0 15px 94px;
    border-left: 1px solid #eff3f9;
    background: url(../images/icon-home-assistant.png) no-repeat left 14px center;
    @media screen and (max-width: 992px) {
      border: 0;
      background-position: left 0;
      padding-left: 80px;
    }
    @media screen and (max-width: 768px) {
      background-size: 50px auto;
      padding-left: 60px;
    }
  }
}

.home-catch-lead{
  font-family:"メイリオ",Meiryo;
  text-align: center;
}

.home-catch-link{
  text-align: center;
}


/*ナビ部分*/
.home-nav{
  margin-bottom: 55px;
  padding-top: 100px;
  background:url(../images/home-nav-bg.png)repeat-x top center;
  ul{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    @media screen and (max-width: 992px) {
      justify-content: space-around;
      flex-wrap: wrap;
    }
    img{
      max-width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    padding-top: 50px;
  }
}

/*ここが強い！東京海上日動の海外留学・旅行保険　STRENGTHS*/

.home-strengths{
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 75px;
  list-style-type: none;
  li{
    width: 46%;
    background: #eff3f9;
    margin-bottom: 25px;
    padding: 14px;
    @media screen and (max-width: 768px) {
      width: calc( 100% - 28px);
    }
  }
  .home-strengths-block{
    display:flex;
  }
  .home-strengths-image{
    margin-right: 15px;
  }
  .home-strengths-title{
    padding: 10px 0 20px;
    font-size: 20px;
    font-family:'Noto Sans JP';
    font-weight: 700;
    color: #1f518e;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  .home-strengths-text{
    font-family:"メイリオ",Meiryo;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

/*お客様の声　Voice*/

.home-voice{
  padding: 65px 0 95px;
  background: url(../images/voice-bg.jpg)no-repeat center top;
  background-size: cover;
  @media screen and (max-width: 768px) {
    padding: 30px 50px;
  }
  @media screen and (max-width: 480px) {
    padding: 30px 10px;
  }
}

.home-voice-list{
  display:flex;
  justify-content: space-between;
  margin-bottom: 100px;
  @media screen and (max-width: 992px) {
    display: block;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.home-voice-item{
  position: relative;
  display: inline-block;
  padding: 35px 30px 65px;
  width: 26.5%;
  border-radius: 15px;
  font-family:"メイリオ",Meiryo;
  &:nth-child(odd){
    background:#2e507a;
    color: #ffffff;
  }
  &:nth-child(even){
    background:#ffffff;
    color: #333333;
  }
  .home-voice-arrow{
    position: absolute;
    bottom: -26px;
    right: 20px;
  }
  @media screen and (max-width: 992px) {
    width: calc( 100% - 60px );
    margin-bottom: 50px;
  }
}

.home-voice-name{
  position: absolute;
  right: 35px;
  bottom: 30px;
}

/*CONTENT04 お申し込みの流れ Flow*/

#content03{
  padding: 55px 0 100px;
  h2{
    margin-bottom: 45px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0 50px;
  }
}

.home-example-case{
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  li{
    position: relative;
    margin-bottom: 45px;
    padding: 25px 30px 10px;
    width: 26.5%;
    background: #eff3f9;
    @media screen and (max-width: 992px) {
      width: 46.5%;
      padding: 25px 10px 10px;
    }
    @media screen and (max-width: 768px) {
      width: calc( 100% - 20px );
    }
  }
}

.home-example-title{
  margin-bottom: 20px;
  border-bottom : 1px solid #1f518e;
  font-size: 20px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  color: #1f518e;
}

.home-example-text{
  margin-bottom: 28px;
  font-family:"メイリオ",Meiryo;
}

.home-example-money{
  padding: 4px 15px;
  position: absolute;
  right: 10px;
  bottom: -10px;
  background: #ead130;
  font-size: 14px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  span{
    margin-left: 30px;
    font-size: 18px;
    vertical-align: baseline;
  }
}

.complement-block{
  dl{
    display: table;
    width: 100%;
  }
  dt,dd{
    display:table-cell;
  }
  dd{
    padding: 20px 55px;
    border: 1px solid #3aacf3;
    border-left: 0;
    font-family:"メイリオ",Meiryo;
    strong{
      font-weight: 400;
      color: #3aacf3;
    }
  }
  .complement-title {
    position: relative;
    width: 260px;
    background: #1f518e;
    font-size: 24px;
    font-weight: 700;
    font-family:'Noto Sans JP';
    color: #ffffff;
    text-align: center;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -15px;
      border: 15px solid transparent;
      border-left: 15px solid #1f518e;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  &.case{
    .complement-title {
      background-image: url(../images/icon-case.png);
      background-repeat: no-repeat;
      background-position: left 10px center;
      width: auto;
      padding: 0 10px 0 35px;
      font-size: 14px;
      font-weight: 400;
      @media screen and (max-width: 768px) {
        width: 60px;
      }
    }
    dd{
      border: 0;
      background: #eff3f9;
      padding: 13px 25px;
    }
  }
  .three-month-block &{
    @media screen and (max-width: 768px) {
      width: 100%;
      .d-flex,span{
        display:block;
      }
      dd{
        text-align: center;
      }
    }
  }
  .year-block &{
    width: 550px;
    @media screen and (max-width: 992px) {
      margin: 0 auto 20px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      .d-flex,span{
        display:block;
      }
    }
  }
  .insurance{
    @media screen and (max-width: 992px) {
      dt,dd{
        display: block;
      }
      .complement-title{
        width: 100%;
        &:before{
          content:none;
        }
      }
      dd{
        padding: 20px 10px;
        border: 1px solid #3aacf3;
        border-top: 0;
      }
    }
  }
}

.complement-text-between{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    display: block;
    text-align: center;
  }
}

.scroll-table {
  overflow: auto;
  white-space: nowrap;
  table {
    width: 100%;
    margin: 0;
    border: none;
  }
  .year-block &{
    margin-bottom: 25px;
  }
}

.home-example-table{
  tr,td{
    border-right: 1px solid #ffffff;
  }
  tr{
    &:nth-child(odd){
      background: #eff3f9;
    }
    &.fee{
      background: #3aacf3;
      color: #ffffff;
    }
  }
  td{
    padding: 8px 5px;
    font-size: 12px;
    font-family:"メイリオ",Meiryo;
    text-align: right;
  }
  .type{
    border-right: 1px solid #ffffff;
    padding: 5px 0;
    background: #1f518e;
    font-family:"メイリオ",Meiryo;
    color: #ffffff;
  }
  .insurance-amount{
    width: 30px;
    background: #6f94c1;
    color: #ffffff;
    line-height: 1;
    text-align: center;
  }
  .type-name{
    padding: 8px 20px;
    font-size: 12px;
    font-family:"メイリオ",Meiryo;
    text-align: left;
  }
}

.three-month-block{
  display:flex;
  justify-content: space-between;
  margin-bottom: 65px;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.three-month-left{
  a{
    display: inline-block;
    padding: 0 20px 0 0;
    background: url(../images/icon-file.png) no-repeat right center;
    color: #1f518e;
    text-decoration: none;
    border-bottom: 1px solid #1f518e;
    line-height: 1.3;
  }
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
}

.three-month-right{
  .home-example-table{
    margin-bottom: 20px;
    td:not(.insurance-amount){
      padding: 5px 35px 5px 55px;
      @media screen and (max-width: 992px) {
        padding: 5px 15px;
        text-align: center;
      }
    }
    @media screen and (max-width: 992px) {
      width: 100%;
      .type:first-child{
        padding-left: 40px;
      }
    }
  }
}

.period-title{
  margin-bottom: 12px;
  padding-left: 35px;
  background:url(../images/icon-calendar.png)no-repeat left center;
  font-size: 20px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  @media screen and (max-width: 992px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

/*CONTENT04 お申し込みの流れ Flow*/

#content04{
  position: relative;
  margin-bottom: 45px;
  padding: 40px 0 100px;

  h2{
    position: relative;
    margin-left: 140px;
    z-index: 1;
    @media screen and (max-width: 768px) {
      margin-left: 0;
      text-align: center;
    }
  }
}

.blue-bg{
  display:block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../images/blue-bg.png);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.home-flow-block{
  width: 50%;
  max-width: 550px;
  margin-left: auto;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 80%;
    max-width: 100%;
    margin: 30px 0 0 20%;
    img{
      max-width: unset;
    }
  }
  th{
    width: auto;
  }
  td{
    background: #ffffff;
    border-top: 1px solid #edf1f7;
    border-bottom: 1px solid #edf1f7;
    width: 100%;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 700;
    font-family:'Noto Sans JP';
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 480px) {
      line-height: 1.4;
    }
  }
  a{
    margin-left: 5px;
    font-size: 14px;
    color: #3aacf3;
    @media screen and (max-width: 768px) {
      display: block;
      margin-left: 0;
      font-size: 11px;
    }
  }
}

.flow-bg{
  position: absolute;
  bottom: -40px;
  left: 0;
  display: block;
  content: "";
  background:url(../images/content04-image.jpg)no-repeat right center;
  width: 43.5%;
  height: 78%;
  background-size: cover;
}



/* CONTENT05 よくある質問 FAQ */

#content05{
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
}

.faq-item{
  padding: 25px 0 30px;
  border-bottom: 2px solid #1f518e;
  @media screen and (max-width: 768px) {
    padding: 15px 0;
  }
}

.home-faq-q,.home-faq-a{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
    span{
      display: block;
    }
  }
}

.home-faq-q{
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.home-faq-a{
  align-items: flex-start;
}

.home-faq-q-title{
  margin-right: 25px;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #1f518e;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.3;
  }
}

.home-faq-a-title{
  margin: 0 15px 0 25px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.home-faq-q-content{
  font-size: 18px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.home-faq-a-content{
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  font-family:"メイリオ",Meiryo;
}