.gmap {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

a.gmap-btn{
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 6px 0;
  max-width: 243px;
  background: #2a4572;
  background-image: url(../images/icon-target-blank.png);
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  text-decoration:none;
  cursor: pointer;
}

.map-arrow{
  position: relative;
  display: inline-block;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
  &::before,
  &::after{
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }
  &:before{
    left: 0;
    bottom: 5px;
    width: calc( 100% - 20px );
    height: 2px;
    background: #ffffff;
  }
  &:after{
    top: 25px;
    right: 9px;
    width: 10px;
    height: 4px;
    border-top: 2px solid #ffffff;
    transform: rotate(45deg);
  }
}

.affiliates{
  .gmap-btn{
    margin-right: 0;
  }
}

.company-block{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .company-info{
    width: 50%;
    height: auto;
    th{
      border-bottom: 1px solid #ffffff;
      padding: 10px;
      width: 120px;
      background: #1f518e;
      color: #ffffff;
      &.last{
        border-bottom:1px solid #1f518e;
      }
    }
    td{
      border:1px solid #1f518e;
      padding: 10px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      width: 100%;
    }
  }
  .gmap {
    width: 45%;
    padding-bottom: 26.25%;
    iframe,
    object,
    embed {
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    .gmap{
      width: 100%;
      padding-bottom: 56.25%;
    }
  }
}

.organization-info{
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
  th{
    border-bottom: 1px solid #ffffff;
    padding: 10px;
    width: 120px;
    background: #1f518e;
    color: #ffffff;
    &.last{
      border-bottom:1px solid #1f518e;
    }
  }
  td{
    border:1px solid #1f518e;
    padding: 10px;
  }
}