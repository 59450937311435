.header-top-copy{
  img{
    max-width: 100%;
    height: auto;
  }
}

.top-catch-inner{
  display:flex;
  position: relative;
  figure{
    margin-right: 30px;
    position: relative;
    top: -30px;
    @media screen and (max-width: 768px) {
      width: 70%;
      margin-right: 0;
      margin: 0 auto;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.top-catch{
  background:url(../images/top/top-catch-bg.jpg)no-repeat;
  background-size: cover;
}

.top-catch-text{
  padding: 90px 0 60px;
  strong{
    display: inline-block;
    padding: 1px 3px;
    background: #ead130;
    color: #1f518e;
    line-height: 1.4;
  }
  p{
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    width:80%;
    margin: 0 auto;
    padding: 0 0 30px;
  }
  @media screen and (max-width: 560px) {
    width:100%;
  }
}

.top-title{
  margin-bottom: 10px;
  font-size: 28px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  .small{
    display: block;
    font-size: 14px;
    color: #333333;
    line-height: 1;
  }
  .big{
    color: #1f518e;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

/*top-travel*/

.top-travel{
  position: relative;
  @media screen and (max-width: 992px) {
    .content-inner{
      padding: 0;
    }
  }
}

.top-travel-bg{
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #1f518e;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
  }
}
.top-travel-thumb{
  background: url(../images/top/top-travel-bg.jpg)no-repeat;
  background-position: left bottom;
  background-size: cover;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
  @media screen and (max-width: 1200px) {
    background-position: left 30% bottom;
  }
  img{
    display:none;
    @media screen and (max-width: 992px) {
      display:block;
      max-width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 992px) {
    position: static;
    background: none;
    width: 100%;
  }
}

.top-travel-inner{
  padding: 55px 0;
  width:50%;
  text-align: center;
  color:#ffffff;
  p{
    margin-bottom: 25px;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    background-color: #1f518e;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

.top-travel-icon{
  margin-bottom: 15px;
}

.top-travel-bottom{
  margin-bottom: 15px;
}

/*top-individual-customers*/

.top-individual-customers{
  position: relative;
  @media screen and (max-width: 992px) {
    .content-inner{
      padding: 0;
    }
  }
}

.top-individual-customers-bg{
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #eff3f9;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
  }
}
.top-individual-customers-thumb{
  position: relative;
  background: url(../images/top/top-individual-customers-bg.jpg)no-repeat;
  background-size: cover;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  left: 0;
  bottom: 0;
  img{
    display:none;
    @media screen and (max-width: 992px) {
      display:block;
      max-width: 100%;
      height: auto;
    }
    &.top-individual-customers-copy{
      display: block;
      position: absolute;
      right: 100px;
      bottom: 55px;
      @media screen and (max-width: 1140px) {
        right: 10px;
      }
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 992px) {
    position: static;
    background: none;
    width: 100%;
  }
}

.top-individual-customers-inner{
  margin-left: auto;
  padding: 55px 0;
  width:50%;
  height: auto;
  text-align: center;
  color:#333333;
  p{
    margin-bottom: 25px;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
    background-color: #eff3f9;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

.top-individual-customers-icon{
  margin-bottom: 15px;
}

.top-individual-customers-bottom{
  margin-bottom: 15px;
}


/*top-facebook*/

.top-facebook{
  position: relative;
  .content-inner{
    display: flex;
    @media screen and (max-width: 992px) {
      flex-direction: column;
      padding: 0;
    }
  }
}

.top-facebook-bg{
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #1877f2;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
  }
}

.top-facebook-left{
  width:50%;
  padding: 30px 0 0;
  img{
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 992px) {
    order: 2;
    margin: 0 auto;
  }
}

.top-facebook-right{
  padding: 70px 0 55px;
  width:50%;
  height: auto;
  text-align: center;
  color:#ffffff;
  p{
    margin-bottom: 25px;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
    background-color: #1877f2;
    order: 1;
  }
  .fa-facebook{
    margin-bottom: 30px;
    font-size: 165px;
    color:#ffffff;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

/*top-recruit*/

.top-recruit{
  position: relative;
  padding: 80px 0;
  .content-inner{
    display: flex;
    @media screen and (max-width: 992px) {
      flex-direction: column;
      padding: 0;
    }
  }
}

.top-recruit-bg{
  position: absolute;
  display: block;
  z-index: -1;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    order: 2;
  }
}
.top-recruit-thumb{
  background: url(../images/top/top-recruit-bg.jpg)no-repeat;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
  img{
    display:none;
    @media screen and (max-width: 992px) {
      display:block;
      max-width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 992px) {
    position: static;
    background: none;
    width: 100%;
    order: 1;
  }
}

.top-recruit{
  font-family:"メイリオ",Meiryo;
}

.top-recruit-title{
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #1f518e;
  line-height: 1.3;
  span{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
    color:#333333;
  }
  @media screen and (max-width: 992px) {
    font-size: 26px;
  }
}

.top-recruit-bottom{
  margin-bottom: 5px;
}

.top-recruit-left{
  width: 50%;
  @media screen and (max-width: 992px) {
    width: calc( 100% - 20px );
    padding: 55px 10px;
    br{
      display:none;
    }
  }
}

.top-recruit-right{
  position: relative;
  width: 50%;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    text-align: center;
  }
  .top-recruit-link{
    position: absolute;
    left: 0;
    bottom: 0;
    @media screen and (max-width: 992px) {
      display: block;
      position: static;
      margin: 0 auto;
    }
  }
}

.top-contact{
  padding: 70px 0;
  background: url(../images/top/top-contact-bg.jpg)no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

.top-contact-title{
  display:flex;
  margin: 0 0 45px 70px;
  @media screen and (max-width: 992px) {
    display: block;
    margin-left: 30px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
  h3{
    font-size: 28px;
    font-family:'Noto Sans JP';
    font-weight: 700;
    color: #1f518e;
    @media screen and (max-width: 992px) {
      font-size: 20px;
    }
  }
  p{
    margin-left: 40px;
    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
    @media screen and (max-width: 768px) {
      br{
        display:none;
      }
    }
  }
}

.top-contact-list{
  display: flex;
  justify-content: space-between;
  list-style-type:none;
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  img{
    display:block;
    margin: 0 auto 15px;
  }
  li{
    width: 33%;
    border-right: 1px solid #326664;
    text-align:center;
    &:last-child{
      border: 0;
      width: 34%;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
      width: 49%;
      border: 0;
      &:first-child{
        border-right: 1px solid #326664;
      }
      &:last-child{
        border: 0;
        width: 49%;
      }
    }
    @media screen and (max-width: 768px) {
      width:100%;
      border: 0;
      &:first-child{
        border: 0;
      }
      &:last-child{
        border: 0;
        width: 100%;
      }
    }
  }
}

.top-contact-type{
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: #1f518e;
  vertical-align: baseline;
  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
}

.top-contact-number,a.top-contact-number{
  font-size: 36px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #333333;
  vertical-align: baseline;
  text-decoration: none;
  @media screen and (max-width: 992px) {
    font-size: 26px;
  }
}