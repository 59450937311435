footer{
  background: #122e50;
  font-family:"メイリオ",Meiryo;
  color: #ffffff;
  a,a:hover,a:focus,a:active,a:visited{
    color: #ffffff;
    text-decoration:none;
  }
}

.footer-inner{
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  padding: 57px 10px;
  @media screen and (max-width: 992px) {
    display:block;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 10px;
  }
}

.footer-title{
  font-size: 18px;
  font-family:"メイリオ",Meiryo;
  font-weight: 700;
}

.footer-address{
  a{
    border-bottom: 1px solid #ffffff;
    color:#ffffff;
    text-decoration: none;
    &:before {
      padding-right: 2px;
      color: #fff;
      content: "\f3c5";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
  }
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
}

.footer-contact-number{
  display:flex;
  justify-content: space-between;
  margin-bottom: 10px;
  line-height: 1;
  @media screen and (max-width: 992px) {
    display:block;
  }
  a,a:hover,a:focus,a:active,a:visited{
    color: #ffffff!important;
    text-decoration:none!important;
  }
}

.footer-contact-number-item{
  font-family: 'Roboto', sans-serif;
  .font-big{
    font-size: 32px;
    vertical-align: baseline;
  }
  .footer-contact-number &:first-child{
    margin-right: 30px;
  }
  @media screen and (max-width: 992px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    .font-big{
      font-size: 22px;
    }
  }
}

.footer-time{
  margin-bottom: 30px;
  background: rgba(111,119,128,0.8);
  ul{
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
      padding: 7px;
      display: block;
    }
  }
  li{
    padding: 7px;
    font-size: 12px;
    font-family:"メイリオ",Meiryo;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  
}

.footer-contact{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.footer-contact-mail{
  margin-right: 35px;
}

.footer-info{
  border-top: 1px solid #798089;
  .footer-inner{
    padding: 25px 10px;
  }
  a{
    color: #ffffff;
    text-decoration: none;
  }
  ul{
    list-style-type: none;
  }
  li{
    display:inline-block;
    margin-right: 24px;
    font-size: 12px;
    font-family:"メイリオ",Meiryo;
    &:last-child{
      margin-right: 0;
    }
  }
  .footer-copy-right{
    font-size: 13px;
    font-family:"メイリオ",Meiryo;
    color: #798089;
  }
}