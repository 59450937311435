.solicitation-policy{
  > section{
    margin-bottom: 30px;
  }
  ul{
    list-style-position: inside;
  }
}

.privacy-block{
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #333333;
  p,ol{
    margin-bottom: 20px;
  }
  dl{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    } 
  }
  dt{
    font-weight: 700;
  }
  ol{
    list-style-position: inside;
  }
}

.privacy-contact-block{
  border: 1px solid #122e50;
}

.privacy-contact-title{
  padding: 20px 0;
  background: #122e50;
  font-size: 20px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
    font-size: 18px;
  }
}

.privacy-contact-text{
  padding: 20px 10px;
  text-align: center;
  ul{
    list-style-type: none;
  }
}