.content-inner{
  max-width: 1140px;
  padding: 0 10px;
  margin: 0 auto;
}
.w1140 {
  width: 1140px;
  margin: 0 auto;
  padding-top: 100px;
}
