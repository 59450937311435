.header-inner{
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  padding: 10px;
  margin: 0 auto;
}

.header-bg{
  background: #1f518e;
}

.header-logo{
  max-width: 216px;
  height: auto;
}

.header-info{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 90px;
}

.header-sns{
  display:flex;
  margin-right: 55px;
  i{
    font-size: 20px;
    color: #ffffff;
  }
  li{
    list-style-type: none;
    &:first-child{
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  .line img{
    width: auto;
    height: 20px;
  }
}

.header-contact{
  line-height: 1;
  text-align: center;
  p{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    text-decoration: none;
    letter-spacing: 0.1em;
    .font-big{
      font-size: 28px;
      vertical-align: baseline;
    }
    .header-time{
      font-size: 14px;
      font-family:"メイリオ",Meiryo;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.header-copy{
  margin: 0 auto;
  max-width: 871px;
  height: auto;
  img{
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    img{
      width: 90%;
      max-width:90%;
      height: auto;
      margin: 0 auto;
    }
  }
}

nav.nav {
  display: none;
  position: absolute;
  top: 68px;
  left:0;
  z-index: 2000;
  color: #000;
  width: 100%;
  border:0;
  padding:0;
  ul {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    background-color: #fff;
    &.sp-nav-menu-secon{
      padding: 0;
      a{
        padding-left: 35px;
        background: #eeeeee;
      }
    }
    li {
      margin-bottom: 0;
      font-size: 14px;
      list-style-type: none;
      padding: 0;
      width: 100%;
      &:last-child {
          padding-bottom: 0;
          border-bottom: none;
      }
      a {
        display: block;
        color: #333333;
        border-bottom: 1px solid #cccccc;
        padding: 20px 26px;
        text-decoration: none;
        text-align: left;
        &:hover{
          text-decoration: underline;
        }
        &.current{
          background-color: #1f518e;
          color: #ffffff;
        }
      }
      > ul li{
        width: calc( 100% - 14px );
        padding-left: 14px;
      }
    }
  }
  p{
    display: block;
    color: #333333;
    border-bottom: 1px solid #cccccc;
    padding: 20px 26px;
    text-align: left;
  }
  > ul{
    overflow-y: scroll;
    height: calc( 100vh - 68px );
  }
}

.navToggle{
  display: block;
  position: absolute;    /* bodyに対しての絶対位置指定 */
  right: 15px;
  top: 25px;
  width: 26px;
  height: 24px;
  cursor: pointer;
  z-index: 2000;
  text-align: center;
  span {
    display: block;
    position: absolute;    /* .navToggleに対して */
    width: 26px;
    border-bottom: solid 3px #ead130;
    transition: .35s ease-in-out;
    left: 6px;
  }
   
  span:nth-child(1) {
    top: 0;
  }
   
  span:nth-child(2) {
    top: 9px;
  }
   
  span:nth-child(3) {
    top: 18px;
  }
  /* 最初のspanをマイナス45度に */
  &.active span:nth-child(1) {
    top: 12px;
    left: 6px;
    transform: rotate(-45deg);
  }
   
  /* 2番目と3番目のspanを45度に */
  &.active span:nth-child(2),
  &.active span:nth-child(3) {
    top: 12px;
    transform: rotate(45deg);
  }
}