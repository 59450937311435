.contact-mv{
  .content-inner{
    padding: 30px 10px;
  }
}

.contact-mv-title{
  margin-bottom: 20px;
  font-size: 20px;
  font-family:'Noto Sans JP';
  font-weight: 700;
}

.contact-mv-text{
  margin-bottom: 20px;
}

.contact-mv-list{
  display:flex;
  margin-bottom: 20px;
  list-style-type: none;
  li{
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    li{
      width:48%;
      margin-right: 0;
      text-align:center;
    }
  }
  @media screen and (max-width: 560px) {
    display:block;
    li{
      width:100%;
      margin: 0 0 10px 0;
    }
  }
}

.contact-mv-type{
  background: #1f518e;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.contact-mv-number{
  padding: 5px 20px;
  background-color: #ffffff;
  a{
    color: #333333;
    text-decoration: none;
  }
  img{
    margin-right: 10px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: auto;
    }
  }
  font-size: 36px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #333333;
  vertical-align: baseline;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.contact-data-block{
  display:flex;
  margin-bottom: 10px;
}

.contact-data-title{
  padding: 3px 5px;
  width: 90px;
  background: #333333;
  color: #ffffff;
  text-align: center;
}

.contact-data-text{
  padding: 3px 15px;
  width: 210px;
  background: #ffffff;
  font-weight: 700;
}

p .required{
  margin-right: 5px;
  padding: 2px 3px;
  background: #ff778f;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
}

.contact-form{
  width: 100%;
  height: auto;
  tr{
    &:last-child{
      border-bottom:1px solid #1f518e;
    }
  }
  th{
    background: #cccccc;
    padding: 10px;
    width: 300px;
    height: auto;
    color: #333333;
    border-bottom:1px solid #ffffff;
    text-align:left;
    .required{
      float:right;
      margin-left: 10px;
      padding: 2px 3px;
      background: #ff778f;
      border-radius: 3px;
      font-size: 14px;
      color: #ffffff;
    }
    .any{
      float:right;
      margin-left: 10px;
      padding: 2px 3px;
      background: #2b6dd3;
      border-radius: 3px;
      font-size: 14px;
      color: #ffffff;
    }
  }
  td{
    border-bottom:1px solid #ffffff;
    background:#f9f9f9;
    padding: 10px;
    width: calc( 100% - 20px);
    input[type="text"],textarea{
      background:#f9f9f9;
      padding: 3px;
      border: 0;
      width: 100%;
      font-size: 14px;
    }
    input[type="radio"]{
      vertical-align:text-top;
    }
  }
  @media screen and (max-width: 768px) {
    th{
      display: block;
      width: calc( 100% - 20px );
      border-bottom: 0;
      text-align: left
    }
    td{
      display: block;
      width: calc( 100% - 20px );
      .required,.any{
        float:none;
      }
    }
  }
}

.contact-form{
  .privacy-block{
    height: 200px;
    overflow-y: scroll;
  }
}

.formBtn{
  text-align: center;
  button{
    width: 240px;
    margin: 0 auto 20px;
    padding: 10px 30px;
    background: #1f518e;
    font-size: 20px;
    color: #ffffff;
  }
}