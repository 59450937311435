@charset "UTF-8";
a:hover {
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  img {
    max-width: 100%;
    height: auto;
  }
}

.content-inner {
  max-width: 1140px;
  padding: 0 10px;
  margin: 0 auto;
}

.w1140 {
  width: 1140px;
  margin: 0 auto;
  padding-top: 100px;
}

.header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  padding: 10px;
  margin: 0 auto;
}

.header-bg {
  background: #1f518e;
}

.header-logo {
  max-width: 216px;
  height: auto;
}

.header-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 90px;
}

.header-sns {
  display: flex;
  margin-right: 55px;
}

.header-sns i {
  font-size: 20px;
  color: #ffffff;
}

.header-sns li {
  list-style-type: none;
}

.header-sns li:first-child {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .header-sns {
    display: none;
  }
}

.header-sns .line img {
  width: auto;
  height: 20px;
}

.header-contact {
  line-height: 1;
  text-align: center;
}

.header-contact p {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 0.1em;
}

.header-contact p .font-big {
  font-size: 28px;
  vertical-align: baseline;
}

.header-contact p .header-time {
  font-size: 14px;
  font-family: "メイリオ",Meiryo;
}

@media screen and (max-width: 768px) {
  .header-contact {
    display: none;
  }
}

.header-copy {
  margin: 0 auto;
  max-width: 871px;
  height: auto;
}

.header-copy img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .header-copy img {
    width: 90%;
    max-width: 90%;
    height: auto;
    margin: 0 auto;
  }
}

nav.nav {
  display: none;
  position: absolute;
  top: 68px;
  left: 0;
  z-index: 2000;
  color: #000;
  width: 100%;
  border: 0;
  padding: 0;
}

nav.nav ul {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background-color: #fff;
}

nav.nav ul.sp-nav-menu-secon {
  padding: 0;
}

nav.nav ul.sp-nav-menu-secon a {
  padding-left: 35px;
  background: #eeeeee;
}

nav.nav ul li {
  margin-bottom: 0;
  font-size: 14px;
  list-style-type: none;
  padding: 0;
  width: 100%;
}

nav.nav ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

nav.nav ul li a {
  display: block;
  color: #333333;
  border-bottom: 1px solid #cccccc;
  padding: 20px 26px;
  text-decoration: none;
  text-align: left;
}

nav.nav ul li a:hover {
  text-decoration: underline;
}

nav.nav ul li a.current {
  background-color: #1f518e;
  color: #ffffff;
}

nav.nav ul li > ul li {
  width: calc( 100% - 14px);
  padding-left: 14px;
}

nav.nav p {
  display: block;
  color: #333333;
  border-bottom: 1px solid #cccccc;
  padding: 20px 26px;
  text-align: left;
}

nav.nav > ul {
  overflow-y: scroll;
  height: calc( 100vh - 68px);
}

.navToggle {
  display: block;
  position: absolute;
  /* bodyに対しての絶対位置指定 */
  right: 15px;
  top: 25px;
  width: 26px;
  height: 24px;
  cursor: pointer;
  z-index: 2000;
  text-align: center;
  /* 最初のspanをマイナス45度に */
  /* 2番目と3番目のspanを45度に */
}

.navToggle span {
  display: block;
  position: absolute;
  /* .navToggleに対して */
  width: 26px;
  border-bottom: solid 3px #ead130;
  transition: .35s ease-in-out;
  left: 6px;
}

.navToggle span:nth-child(1) {
  top: 0;
}

.navToggle span:nth-child(2) {
  top: 9px;
}

.navToggle span:nth-child(3) {
  top: 18px;
}

.navToggle.active span:nth-child(1) {
  top: 12px;
  left: 6px;
  transform: rotate(-45deg);
}

.navToggle.active span:nth-child(2),
.navToggle.active span:nth-child(3) {
  top: 12px;
  transform: rotate(45deg);
}

footer {
  background: #122e50;
  font-family: "メイリオ",Meiryo;
  color: #ffffff;
}

footer a, footer a:hover, footer a:focus, footer a:active, footer a:visited {
  color: #ffffff;
  text-decoration: none;
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  padding: 57px 10px;
}

@media screen and (max-width: 992px) {
  .footer-inner {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .footer-inner {
    padding: 30px 10px;
  }
}

.footer-title {
  font-size: 18px;
  font-family: "メイリオ",Meiryo;
  font-weight: 700;
}

.footer-address a {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  text-decoration: none;
}

.footer-address a:before {
  padding-right: 2px;
  color: #fff;
  content: "\f3c5";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

@media screen and (max-width: 992px) {
  .footer-address {
    margin-bottom: 20px;
  }
}

.footer-contact-number {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  line-height: 1;
}

@media screen and (max-width: 992px) {
  .footer-contact-number {
    display: block;
  }
}

.footer-contact-number a, .footer-contact-number a:hover, .footer-contact-number a:focus, .footer-contact-number a:active, .footer-contact-number a:visited {
  color: #ffffff !important;
  text-decoration: none !important;
}

.footer-contact-number-item {
  font-family: 'Roboto', sans-serif;
}

.footer-contact-number-item .font-big {
  font-size: 32px;
  vertical-align: baseline;
}

.footer-contact-number .footer-contact-number-item:first-child {
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .footer-contact-number-item {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .footer-contact-number-item .font-big {
    font-size: 22px;
  }
}

.footer-time {
  margin-bottom: 30px;
  background: rgba(111, 119, 128, 0.8);
}

.footer-time ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .footer-time ul {
    margin-top: 30px;
    padding: 7px;
    display: block;
  }
}

.footer-time li {
  padding: 7px;
  font-size: 12px;
  font-family: "メイリオ",Meiryo;
}

@media screen and (max-width: 768px) {
  .footer-time li {
    padding: 0;
  }
}

.footer-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .footer-contact {
    display: block;
  }
}

.footer-contact-mail {
  margin-right: 35px;
}

.footer-info {
  border-top: 1px solid #798089;
}

.footer-info .footer-inner {
  padding: 25px 10px;
}

.footer-info a {
  color: #ffffff;
  text-decoration: none;
}

.footer-info ul {
  list-style-type: none;
}

.footer-info li {
  display: inline-block;
  margin-right: 24px;
  font-size: 12px;
  font-family: "メイリオ",Meiryo;
}

.footer-info li:last-child {
  margin-right: 0;
}

.footer-info .footer-copy-right {
  font-size: 13px;
  font-family: "メイリオ",Meiryo;
  color: #798089;
}

/*コーディング時には、url(../)と階層をひとつ上げることを忘れない*/
.cd-bg-1 {
  background: url(../images/bg01.jpg) no-repeat top center;
  background-size: cover;
}

.cd-bg-2 {
  background: url(../images/bg02.jpg) no-repeat bottom center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

@media screen and (max-width: 768px) {
  .content-pc-title {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .content-sp-title {
    display: none;
  }
}

/*メッセージ上*/
.home-about-block {
  display: flex;
  align-items: flex-end;
  padding: 85px;
}

@media screen and (max-width: 992px) {
  .home-about-block {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .home-about-block {
    padding: 30px 0;
  }
}

.home-about-content {
  margin-right: 25px;
}

@media screen and (max-width: 992px) {
  .home-about-content {
    margin-right: 0;
  }
}

.home-about-title {
  margin: 0 25px 0 0;
  font-size: 36px;
  font-weight: 400;
  color: #1f518e;
}

@media screen and (max-width: 992px) {
  .home-about-title {
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

@media screen and (max-width: 768px) {
  .home-about-title {
    font-size: 26px;
  }
}

.home-about-text {
  font-size: 16px;
  line-height: 2;
}

@media screen and (max-width: 992px) {
  .home-about-text {
    margin-bottom: 20px;
  }
  .home-about-text br {
    display: none;
  }
}

/*メッセージ下*/
.home-catch {
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  .home-catch {
    padding: 50px 0;
  }
}

.home-catch-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 940px;
  margin: 0 auto 60px;
  padding: 0 10px;
}

@media screen and (max-width: 992px) {
  .home-catch-inner {
    display: block;
  }
  .home-catch-inner figure {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .home-catch-inner {
    margin-bottom: 30px;
  }
}

.home-catch-title {
  margin: 0 0 30px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .home-catch-title {
    font-size: 18px;
  }
}

.home-catch-title strong {
  font-size: 34px;
  color: #ead130;
  vertical-align: baseline;
}

@media screen and (max-width: 768px) {
  .home-catch-title strong {
    font-size: 24px;
  }
}

.home-catch-text span {
  padding: 2px 0;
  background: #ead130;
  font-weight: 700;
  font-family: " メイリオ",Meiryo;
  color: #1f518e;
}

.home-catch-title-bg {
  margin-bottom: 2px;
  display: inline-block;
  padding: 0 10px;
  background: #1f518e;
  line-height: 1.5;
  vertical-align: bottom;
}

@media screen and (max-width: 992px) {
  .home-catch-title-bg {
    display: block;
    margin-bottom: 0;
  }
}

.home-catch-text {
  font-size: 16px;
}

.home-catch-strength {
  margin-bottom: 50px;
  padding: 30px 30px 40px;
  background: rgba(74, 75, 75, 0.18);
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .home-catch-strength {
    margin-bottom: 30px;
    padding: 15px 30px;
  }
}

.home-catch-strength-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .home-catch-strength-list {
    display: block;
  }
}

.home-catch-strength-title {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .home-catch-strength-title {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .home-catch-strength-title {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.home-catch-strength-list li {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: 'Noto Sans JP';
  line-height: 1.2;
  padding: 5px 0 15px 80px;
}

.home-catch-strength-list li span {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .home-catch-strength-list li {
    margin-bottom: 0;
    padding: 0 0 15px 60px;
    font-size: 16px;
  }
  .home-catch-strength-list li span {
    font-size: 18px;
  }
}

.home-catch-strength-list .one {
  background: url(../images/icon-home-tel.png) no-repeat;
}

@media screen and (max-width: 768px) {
  .home-catch-strength-list .one {
    background-size: 50px auto;
  }
}

.home-catch-strength-list .two {
  padding: 5px 0 15px 94px;
  border-left: 1px solid #eff3f9;
  background: url(../images/icon-home-money.png) no-repeat left 14px center;
}

@media screen and (max-width: 992px) {
  .home-catch-strength-list .two {
    border: 0;
    background-position: left 0;
    padding-left: 80px;
  }
}

@media screen and (max-width: 768px) {
  .home-catch-strength-list .two {
    background-size: 50px auto;
    padding-left: 60px;
  }
}

.home-catch-strength-list .three {
  padding: 5px 0 15px 94px;
  border-left: 1px solid #eff3f9;
  background: url(../images/icon-home-assistant.png) no-repeat left 14px center;
}

@media screen and (max-width: 992px) {
  .home-catch-strength-list .three {
    border: 0;
    background-position: left 0;
    padding-left: 80px;
  }
}

@media screen and (max-width: 768px) {
  .home-catch-strength-list .three {
    background-size: 50px auto;
    padding-left: 60px;
  }
}

.home-catch-lead {
  font-family: "メイリオ",Meiryo;
  text-align: center;
}

.home-catch-link {
  text-align: center;
}

/*ナビ部分*/
.home-nav {
  margin-bottom: 55px;
  padding-top: 100px;
  background: url(../images/home-nav-bg.png) repeat-x top center;
}

.home-nav ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .home-nav ul {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.home-nav ul img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .home-nav {
    margin-bottom: 30px;
    padding-top: 50px;
  }
}

/*ここが強い！東京海上日動の海外留学・旅行保険　STRENGTHS*/
.home-strengths {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 75px;
  list-style-type: none;
}

.home-strengths li {
  width: 46%;
  background: #eff3f9;
  margin-bottom: 25px;
  padding: 14px;
}

@media screen and (max-width: 768px) {
  .home-strengths li {
    width: calc( 100% - 28px);
  }
}

.home-strengths .home-strengths-block {
  display: flex;
}

.home-strengths .home-strengths-image {
  margin-right: 15px;
}

.home-strengths .home-strengths-title {
  padding: 10px 0 20px;
  font-size: 20px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .home-strengths .home-strengths-title {
    font-size: 18px;
  }
}

.home-strengths .home-strengths-text {
  font-family: "メイリオ",Meiryo;
}

@media screen and (max-width: 768px) {
  .home-strengths {
    margin-bottom: 30px;
  }
}

/*お客様の声　Voice*/
.home-voice {
  padding: 65px 0 95px;
  background: url(../images/voice-bg.jpg) no-repeat center top;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .home-voice {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 480px) {
  .home-voice {
    padding: 30px 10px;
  }
}

.home-voice-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .home-voice-list {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .home-voice-list {
    margin-bottom: 50px;
  }
}

.home-voice-item {
  position: relative;
  display: inline-block;
  padding: 35px 30px 65px;
  width: 26.5%;
  border-radius: 15px;
  font-family: "メイリオ",Meiryo;
}

.home-voice-item:nth-child(odd) {
  background: #2e507a;
  color: #ffffff;
}

.home-voice-item:nth-child(even) {
  background: #ffffff;
  color: #333333;
}

.home-voice-item .home-voice-arrow {
  position: absolute;
  bottom: -26px;
  right: 20px;
}

@media screen and (max-width: 992px) {
  .home-voice-item {
    width: calc( 100% - 60px);
    margin-bottom: 50px;
  }
}

.home-voice-name {
  position: absolute;
  right: 35px;
  bottom: 30px;
}

/*CONTENT04 お申し込みの流れ Flow*/
#content03 {
  padding: 55px 0 100px;
}

#content03 h2 {
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  #content03 {
    padding: 30px 0 50px;
  }
}

.home-example-case {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .home-example-case {
    display: block;
  }
}

.home-example-case li {
  position: relative;
  margin-bottom: 45px;
  padding: 25px 30px 10px;
  width: 26.5%;
  background: #eff3f9;
}

@media screen and (max-width: 992px) {
  .home-example-case li {
    width: 46.5%;
    padding: 25px 10px 10px;
  }
}

@media screen and (max-width: 768px) {
  .home-example-case li {
    width: calc( 100% - 20px);
  }
}

.home-example-title {
  margin-bottom: 20px;
  border-bottom: 1px solid #1f518e;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #1f518e;
}

.home-example-text {
  margin-bottom: 28px;
  font-family: "メイリオ",Meiryo;
}

.home-example-money {
  padding: 4px 15px;
  position: absolute;
  right: 10px;
  bottom: -10px;
  background: #ead130;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
}

.home-example-money span {
  margin-left: 30px;
  font-size: 18px;
  vertical-align: baseline;
}

.complement-block dl {
  display: table;
  width: 100%;
}

.complement-block dt, .complement-block dd {
  display: table-cell;
}

.complement-block dd {
  padding: 20px 55px;
  border: 1px solid #3aacf3;
  border-left: 0;
  font-family: "メイリオ",Meiryo;
}

.complement-block dd strong {
  font-weight: 400;
  color: #3aacf3;
}

.complement-block .complement-title {
  position: relative;
  width: 260px;
  background: #1f518e;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #ffffff;
  text-align: center;
}

.complement-block .complement-title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -15px;
  border: 15px solid transparent;
  border-left: 15px solid #1f518e;
}

@media screen and (max-width: 768px) {
  .complement-block .complement-title {
    font-size: 18px;
  }
}

.complement-block.case .complement-title {
  background-image: url(../images/icon-case.png);
  background-repeat: no-repeat;
  background-position: left 10px center;
  width: auto;
  padding: 0 10px 0 35px;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .complement-block.case .complement-title {
    width: 60px;
  }
}

.complement-block.case dd {
  border: 0;
  background: #eff3f9;
  padding: 13px 25px;
}

@media screen and (max-width: 768px) {
  .three-month-block .complement-block {
    width: 100%;
  }
  .three-month-block .complement-block .d-flex, .three-month-block .complement-block span {
    display: block;
  }
  .three-month-block .complement-block dd {
    text-align: center;
  }
}

.year-block .complement-block {
  width: 550px;
}

@media screen and (max-width: 992px) {
  .year-block .complement-block {
    margin: 0 auto 20px;
  }
}

@media screen and (max-width: 768px) {
  .year-block .complement-block {
    width: 100%;
  }
  .year-block .complement-block .d-flex, .year-block .complement-block span {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .complement-block .insurance dt, .complement-block .insurance dd {
    display: block;
  }
  .complement-block .insurance .complement-title {
    width: 100%;
  }
  .complement-block .insurance .complement-title:before {
    content: none;
  }
  .complement-block .insurance dd {
    padding: 20px 10px;
    border: 1px solid #3aacf3;
    border-top: 0;
  }
}

.complement-text-between {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .complement-text-between {
    display: block;
    text-align: center;
  }
}

.scroll-table {
  overflow: auto;
  white-space: nowrap;
}

.scroll-table table {
  width: 100%;
  margin: 0;
  border: none;
}

.year-block .scroll-table {
  margin-bottom: 25px;
}

.home-example-table tr, .home-example-table td {
  border-right: 1px solid #ffffff;
}

.home-example-table tr:nth-child(odd) {
  background: #eff3f9;
}

.home-example-table tr.fee {
  background: #3aacf3;
  color: #ffffff;
}

.home-example-table td {
  padding: 8px 5px;
  font-size: 12px;
  font-family: "メイリオ",Meiryo;
  text-align: right;
}

.home-example-table .type {
  border-right: 1px solid #ffffff;
  padding: 5px 0;
  background: #1f518e;
  font-family: "メイリオ",Meiryo;
  color: #ffffff;
}

.home-example-table .insurance-amount {
  width: 30px;
  background: #6f94c1;
  color: #ffffff;
  line-height: 1;
  text-align: center;
}

.home-example-table .type-name {
  padding: 8px 20px;
  font-size: 12px;
  font-family: "メイリオ",Meiryo;
  text-align: left;
}

.three-month-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
}

@media screen and (max-width: 992px) {
  .three-month-block {
    display: block;
  }
}

.three-month-left a {
  display: inline-block;
  padding: 0 20px 0 0;
  background: url(../images/icon-file.png) no-repeat right center;
  color: #1f518e;
  text-decoration: none;
  border-bottom: 1px solid #1f518e;
  line-height: 1.3;
}

@media screen and (max-width: 992px) {
  .three-month-left {
    margin-bottom: 20px;
  }
}

.three-month-right .home-example-table {
  margin-bottom: 20px;
}

.three-month-right .home-example-table td:not(.insurance-amount) {
  padding: 5px 35px 5px 55px;
}

@media screen and (max-width: 992px) {
  .three-month-right .home-example-table td:not(.insurance-amount) {
    padding: 5px 15px;
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .three-month-right .home-example-table {
    width: 100%;
  }
  .three-month-right .home-example-table .type:first-child {
    padding-left: 40px;
  }
}

.period-title {
  margin-bottom: 12px;
  padding-left: 35px;
  background: url(../images/icon-calendar.png) no-repeat left center;
  font-size: 20px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .period-title {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .period-title {
    font-size: 18px;
  }
}

/*CONTENT04 お申し込みの流れ Flow*/
#content04 {
  position: relative;
  margin-bottom: 45px;
  padding: 40px 0 100px;
}

#content04 h2 {
  position: relative;
  margin-left: 140px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  #content04 h2 {
    margin-left: 0;
    text-align: center;
  }
}

.blue-bg {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../images/blue-bg.png);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.home-flow-block {
  width: 50%;
  max-width: 550px;
  margin-left: auto;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .home-flow-block {
    width: 80%;
    max-width: 100%;
    margin: 30px 0 0 20%;
  }
  .home-flow-block img {
    max-width: unset;
  }
}

.home-flow-block th {
  width: auto;
}

.home-flow-block td {
  background: #ffffff;
  border-top: 1px solid #edf1f7;
  border-bottom: 1px solid #edf1f7;
  width: 100%;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
}

@media screen and (max-width: 768px) {
  .home-flow-block td {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .home-flow-block td {
    line-height: 1.4;
  }
}

.home-flow-block a {
  margin-left: 5px;
  font-size: 14px;
  color: #3aacf3;
}

@media screen and (max-width: 768px) {
  .home-flow-block a {
    display: block;
    margin-left: 0;
    font-size: 11px;
  }
}

.flow-bg {
  position: absolute;
  bottom: -40px;
  left: 0;
  display: block;
  content: "";
  background: url(../images/content04-image.jpg) no-repeat right center;
  width: 43.5%;
  height: 78%;
  background-size: cover;
}

/* CONTENT05 よくある質問 FAQ */
#content05 {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  #content05 {
    padding-bottom: 50px;
  }
}

.faq-item {
  padding: 25px 0 30px;
  border-bottom: 2px solid #1f518e;
}

@media screen and (max-width: 768px) {
  .faq-item {
    padding: 15px 0;
  }
}

.home-faq-q, .home-faq-a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .home-faq-q, .home-faq-a {
    display: block;
  }
  .home-faq-q span, .home-faq-a span {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .home-faq-q {
    margin-bottom: 20px;
  }
}

.home-faq-a {
  align-items: flex-start;
}

.home-faq-q-title {
  margin-right: 25px;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .home-faq-q-title {
    font-size: 20px;
    line-height: 1.3;
  }
}

.home-faq-a-title {
  margin: 0 15px 0 25px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .home-faq-a-title {
    margin-left: 0;
  }
}

.home-faq-q-content {
  font-size: 18px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
}

@media screen and (max-width: 768px) {
  .home-faq-q-content {
    font-size: 16px;
  }
}

.home-faq-a-content {
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "メイリオ",Meiryo;
}

.mv.home {
  background: url(../images/mv.jpg) no-repeat;
  background-size: cover;
}

.mv.top-mv {
  background: url(../images/top/top-mv.jpg) no-repeat center center;
  background-size: cover;
}

.mv.item-list1-mv, .mv.item-list2-mv, .mv.organization-mv {
  background: url(../images/page-mv.jpg) no-repeat center center;
  background-size: cover;
}

.mv.contact-mv {
  background: url(../images/contact/contact-mv.jpg) no-repeat center center;
  background-size: cover;
}

.header-copy {
  text-align: center;
  padding: 240px 0;
}

@media screen and (max-width: 992px) {
  .header-copy {
    padding: 100px 0;
  }
}

.page-mv-copy {
  text-align: center;
  padding: 100px 0;
  font-size: 38px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0.1em;
}

.page-mv-copy .corporation {
  display: inline-block;
  color: #4983cf;
  vertical-align: top;
}

.page-mv-copy .proprietorship {
  display: inline-block;
  color: #e0610d;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .page-mv-copy {
    font-size: 28px;
  }
}

.header-top-copy img {
  max-width: 100%;
  height: auto;
}

.top-catch-inner {
  display: flex;
  position: relative;
}

.top-catch-inner figure {
  margin-right: 30px;
  position: relative;
  top: -30px;
}

@media screen and (max-width: 768px) {
  .top-catch-inner figure {
    width: 70%;
    margin-right: 0;
    margin: 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .top-catch-inner {
    display: block;
  }
}

.top-catch {
  background: url(../images/top/top-catch-bg.jpg) no-repeat;
  background-size: cover;
}

.top-catch-text {
  padding: 90px 0 60px;
}

.top-catch-text strong {
  display: inline-block;
  padding: 1px 3px;
  background: #ead130;
  color: #1f518e;
  line-height: 1.4;
}

.top-catch-text p {
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .top-catch-text {
    width: 80%;
    margin: 0 auto;
    padding: 0 0 30px;
  }
}

@media screen and (max-width: 560px) {
  .top-catch-text {
    width: 100%;
  }
}

.top-title {
  margin-bottom: 10px;
  font-size: 28px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
}

.top-title .small {
  display: block;
  font-size: 14px;
  color: #333333;
  line-height: 1;
}

.top-title .big {
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .top-title {
    font-size: 20px;
  }
}

/*top-travel*/
.top-travel {
  position: relative;
}

@media screen and (max-width: 992px) {
  .top-travel .content-inner {
    padding: 0;
  }
}

.top-travel-bg {
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #1f518e;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .top-travel-bg {
    position: static;
    width: 100%;
  }
}

.top-travel-thumb {
  background: url(../images/top/top-travel-bg.jpg) no-repeat;
  background-position: left bottom;
  background-size: cover;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 1200px) {
  .top-travel-thumb {
    background-position: left 30% bottom;
  }
}

.top-travel-thumb img {
  display: none;
}

@media screen and (max-width: 992px) {
  .top-travel-thumb img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .top-travel-thumb {
    position: static;
    background: none;
    width: 100%;
  }
}

.top-travel-inner {
  padding: 55px 0;
  width: 50%;
  text-align: center;
  color: #ffffff;
}

.top-travel-inner p {
  margin-bottom: 25px;
}

@media screen and (max-width: 992px) {
  .top-travel-inner {
    width: 100%;
    background-color: #1f518e;
  }
}

@media screen and (max-width: 768px) {
  .top-travel-inner {
    padding: 30px 0;
  }
}

.top-travel-icon {
  margin-bottom: 15px;
}

.top-travel-bottom {
  margin-bottom: 15px;
}

/*top-individual-customers*/
.top-individual-customers {
  position: relative;
}

@media screen and (max-width: 992px) {
  .top-individual-customers .content-inner {
    padding: 0;
  }
}

.top-individual-customers-bg {
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #eff3f9;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .top-individual-customers-bg {
    position: static;
    width: 100%;
  }
}

.top-individual-customers-thumb {
  position: relative;
  background: url(../images/top/top-individual-customers-bg.jpg) no-repeat;
  background-size: cover;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  left: 0;
  bottom: 0;
}

.top-individual-customers-thumb img {
  display: none;
}

@media screen and (max-width: 992px) {
  .top-individual-customers-thumb img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.top-individual-customers-thumb img.top-individual-customers-copy {
  display: block;
  position: absolute;
  right: 100px;
  bottom: 55px;
}

@media screen and (max-width: 1140px) {
  .top-individual-customers-thumb img.top-individual-customers-copy {
    right: 10px;
  }
}

@media screen and (max-width: 992px) {
  .top-individual-customers-thumb img.top-individual-customers-copy {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .top-individual-customers-thumb {
    position: static;
    background: none;
    width: 100%;
  }
}

.top-individual-customers-inner {
  margin-left: auto;
  padding: 55px 0;
  width: 50%;
  height: auto;
  text-align: center;
  color: #333333;
}

.top-individual-customers-inner p {
  margin-bottom: 25px;
}

@media screen and (max-width: 992px) {
  .top-individual-customers-inner {
    width: 100%;
    margin-left: 0;
    background-color: #eff3f9;
  }
}

@media screen and (max-width: 768px) {
  .top-individual-customers-inner {
    padding: 30px 0;
  }
}

.top-individual-customers-icon {
  margin-bottom: 15px;
}

.top-individual-customers-bottom {
  margin-bottom: 15px;
}

/*top-facebook*/
.top-facebook {
  position: relative;
}

.top-facebook .content-inner {
  display: flex;
}

@media screen and (max-width: 992px) {
  .top-facebook .content-inner {
    flex-direction: column;
    padding: 0;
  }
}

.top-facebook-bg {
  position: absolute;
  display: block;
  z-index: -1;
  background-color: #1877f2;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .top-facebook-bg {
    position: static;
    width: 100%;
  }
}

.top-facebook-left {
  width: 50%;
  padding: 30px 0 0;
}

.top-facebook-left img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 992px) {
  .top-facebook-left {
    order: 2;
    margin: 0 auto;
  }
}

.top-facebook-right {
  padding: 70px 0 55px;
  width: 50%;
  height: auto;
  text-align: center;
  color: #ffffff;
}

.top-facebook-right p {
  margin-bottom: 25px;
}

@media screen and (max-width: 992px) {
  .top-facebook-right {
    width: 100%;
    margin-left: 0;
    background-color: #1877f2;
    order: 1;
  }
}

.top-facebook-right .fa-facebook {
  margin-bottom: 30px;
  font-size: 165px;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .top-facebook-right {
    padding: 30px 0;
  }
}

/*top-recruit*/
.top-recruit {
  position: relative;
  padding: 80px 0;
}

.top-recruit .content-inner {
  display: flex;
}

@media screen and (max-width: 992px) {
  .top-recruit .content-inner {
    flex-direction: column;
    padding: 0;
  }
}

.top-recruit-bg {
  position: absolute;
  display: block;
  z-index: -1;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .top-recruit-bg {
    position: static;
    width: 100%;
    order: 2;
  }
}

.top-recruit-thumb {
  background: url(../images/top/top-recruit-bg.jpg) no-repeat;
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
}

.top-recruit-thumb img {
  display: none;
}

@media screen and (max-width: 992px) {
  .top-recruit-thumb img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .top-recruit-thumb {
    position: static;
    background: none;
    width: 100%;
    order: 1;
  }
}

.top-recruit {
  font-family: "メイリオ",Meiryo;
}

.top-recruit-title {
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #1f518e;
  line-height: 1.3;
}

.top-recruit-title span {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

@media screen and (max-width: 992px) {
  .top-recruit-title {
    font-size: 26px;
  }
}

.top-recruit-bottom {
  margin-bottom: 5px;
}

.top-recruit-left {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .top-recruit-left {
    width: calc( 100% - 20px);
    padding: 55px 10px;
  }
  .top-recruit-left br {
    display: none;
  }
}

.top-recruit-right {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 992px) {
  .top-recruit-right {
    position: static;
    width: 100%;
    text-align: center;
  }
}

.top-recruit-right .top-recruit-link {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 992px) {
  .top-recruit-right .top-recruit-link {
    display: block;
    position: static;
    margin: 0 auto;
  }
}

.top-contact {
  padding: 70px 0;
  background: url(../images/top/top-contact-bg.jpg) no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .top-contact {
    padding: 30px 0;
  }
}

.top-contact-title {
  display: flex;
  margin: 0 0 45px 70px;
}

@media screen and (max-width: 992px) {
  .top-contact-title {
    display: block;
    margin-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .top-contact-title {
    margin-bottom: 30px;
  }
}

.top-contact-title h3 {
  font-size: 28px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
}

@media screen and (max-width: 992px) {
  .top-contact-title h3 {
    font-size: 20px;
  }
}

.top-contact-title p {
  margin-left: 40px;
}

@media screen and (max-width: 992px) {
  .top-contact-title p {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .top-contact-title p br {
    display: none;
  }
}

.top-contact-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .top-contact-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.top-contact-list img {
  display: block;
  margin: 0 auto 15px;
}

.top-contact-list li {
  width: 33%;
  border-right: 1px solid #326664;
  text-align: center;
}

.top-contact-list li:last-child {
  border: 0;
  width: 34%;
}

@media screen and (max-width: 992px) {
  .top-contact-list li {
    margin-bottom: 20px;
    width: 49%;
    border: 0;
  }
  .top-contact-list li:first-child {
    border-right: 1px solid #326664;
  }
  .top-contact-list li:last-child {
    border: 0;
    width: 49%;
  }
}

@media screen and (max-width: 768px) {
  .top-contact-list li {
    width: 100%;
    border: 0;
  }
  .top-contact-list li:first-child {
    border: 0;
  }
  .top-contact-list li:last-child {
    border: 0;
    width: 100%;
  }
}

.top-contact-type {
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: #1f518e;
  vertical-align: baseline;
}

@media screen and (max-width: 992px) {
  .top-contact-type {
    font-size: 18px;
  }
}

.top-contact-number, a.top-contact-number {
  font-size: 36px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #333333;
  vertical-align: baseline;
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .top-contact-number, a.top-contact-number {
    font-size: 26px;
  }
}

.page-content {
  padding: 50px 0;
  font-family: "メイリオ",Meiryo;
}

.page-title {
  margin-bottom: 30px;
  border-bottom: solid 3px #cccccc;
  position: relative;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
}

.page-title:after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 3px #1f518e;
  bottom: -3px;
  width: 30%;
}

@media screen and (max-width: 768px) {
  .page-title {
    font-size: 20px;
  }
}

.page-title02 {
  margin-bottom: 10px;
  font-size: 28px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .page-title02 {
    font-size: 18px;
  }
}

.page-title03 {
  margin-bottom: 10px;
  font-size: 20px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .page-title03 {
    font-size: 16px;
  }
}

.item_list_contact {
  position: fixed;
  right: 0;
  bottom: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: auto;
  padding: 10px 0;
  background: #1f518e;
  text-align: center;
}

.item_list_contact a {
  display: block;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #ffffff;
  text-decoration: none;
  line-height: 1.3;
}

.page-link-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .page-link-list {
    display: block;
  }
}

.page-link-list a {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #333333;
  text-decoration: none;
}

.page-link-list .corporation-list {
  position: relative;
  width: 45%;
}

@media screen and (max-width: 992px) {
  .page-link-list .corporation-list {
    margin-bottom: 30px;
    width: 100%;
  }
}

.page-link-list .corporation-list a {
  color: #4983cf;
}

.page-link-list .corporation-list .corporation-list-title {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15px;
  background: #ffffff;
  font-size: 24px;
  color: #333333;
}

.page-link-list .corporation-list .corporation-list-title span {
  color: #4983cf;
}

@media screen and (max-width: 768px) {
  .page-link-list .corporation-list .corporation-list-title {
    top: -10px;
    font-size: 18px;
  }
}

.page-link-list .corporation-list ul {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border: 2px solid #cccccc;
  list-style-type: none;
}

@media screen and (max-width: 560px) {
  .page-link-list .corporation-list ul {
    display: block;
  }
}

.page-link-list .proprietorship-list {
  position: relative;
  width: 45%;
}

@media screen and (max-width: 992px) {
  .page-link-list .proprietorship-list {
    margin-bottom: 30px;
    width: 100%;
  }
}

.page-link-list .proprietorship-list a {
  color: #e0610d;
}

.page-link-list .proprietorship-list .proprietorship-list-title {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15px;
  background: #ffffff;
  font-size: 24px;
  color: #333333;
}

.page-link-list .proprietorship-list .proprietorship-list-title span {
  color: #e0610d;
}

@media screen and (max-width: 768px) {
  .page-link-list .proprietorship-list .proprietorship-list-title {
    top: -10px;
    font-size: 18px;
  }
}

.page-link-list .proprietorship-list ul {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border: 2px solid #cccccc;
  list-style-type: none;
}

@media screen and (max-width: 560px) {
  .page-link-list .proprietorship-list ul {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .page-link-list {
    margin-bottom: 30px;
  }
}

.corporation-msg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.corporation-msg .page-title02 {
  border-bottom: 1px solid #1f518e;
}

@media screen and (max-width: 768px) {
  .corporation-msg {
    display: block;
    margin-bottom: 30px;
  }
}

.corporation-msg-left {
  width: 65%;
}

@media screen and (max-width: 768px) {
  .corporation-msg-left {
    margin-bottom: 30px;
    width: 100%;
  }
}

.corporation-msg-right {
  width: 30%;
  height: auto;
  background: #cccccc;
}

@media screen and (max-width: 768px) {
  .corporation-msg-right {
    width: 100%;
  }
}

.corporation-msg-right img {
  max-width: 100%;
  height: auto;
}

.item_list_box {
  padding: 20px;
}

.item_list_box.blue {
  background-color: #eff3f9;
}

.item_list_box.yellow {
  background-color: #ead130;
}

.item_list_box p:last-child {
  margin-bottom: 0;
}

.item_list_box2 {
  border: 2px solid #1f518e;
}

.item_list_box2 .item_list_inner {
  padding: 20px;
}

.item_list_box2 .item_list_title {
  padding-left: 20px;
  background-color: #1f518e;
  color: #ffffff;
}

.item_list_box2 .item_list_title .page-title03 {
  color: #ffffff;
}

.item_list_box2 p:last-child {
  margin-bottom: 0;
}

.item_list_box3 {
  border: 2px solid #ead130;
}

.item_list_box3 .item_list_inner {
  padding: 20px;
}

.item_list_box3 .item_list_title {
  padding-left: 20px;
  background-color: #ead130;
  color: #ffffff;
}

.item_list_box3 .item_list_title .page-title03 {
  color: #ffffff;
}

.item_list_box3 p:last-child {
  margin-bottom: 0;
}

.corporation-bg {
  margin-bottom: 30px;
  padding: 15px 0;
  background: #ccd5e1;
  text-align: center;
}

.corporation-title {
  font-size: 38px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #0065b8;
}

@media screen and (max-width: 768px) {
  .corporation-title {
    font-size: 24px;
  }
}

.cyber-image-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 100px;
  list-style-type: none;
}

.cyber-image-list li {
  width: 30%;
}

.cyber-image-list li img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 992px) {
  .cyber-image-list li {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .cyber-image-list li {
    width: 48%;
  }
}

.if-title {
  display: inline-block;
  padding: 0 30px;
  border-bottom: 2px solid #1f518e;
  margin: 0 auto 50px;
  font-size: 30px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .if-title {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .if-title {
    padding: 0 15px;
  }
}

.corporation-arrow {
  margin-bottom: 70px;
  position: relative;
  width: 70px;
  height: 101px;
  border-right: 70px solid #0065b8;
  box-sizing: border-box;
  margin: 0 auto;
}

.corporation-arrow::after {
  content: "";
  position: absolute;
  bottom: -70px;
  right: -102px;
  border-top: 70px solid #0065b8;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
}

.cyber-lead {
  margin-bottom: 50px;
  padding: 20px;
  background: #ff0000;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .cyber-lead {
    margin-bottom: 30px;
    font-size: 28px;
  }
}

@media screen and (max-width: 640px) {
  .cyber-lead {
    padding: 10px;
    font-size: 24px;
  }
}

.cyber-block {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .cyber-block {
    display: block;
  }
}

.cyber-left {
  width: 65%;
}

@media screen and (max-width: 768px) {
  .cyber-left {
    margin-bottom: 30px;
    width: 100%;
  }
}

.cyber-right {
  width: 30%;
}

.cyber-right img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .cyber-right {
    width: 100%;
  }
}

.check-list {
  list-style-type: none;
  list-style-position: outside;
}

.check-list li {
  margin-bottom: 5px;
  padding-left: 26px;
  text-indent: -26px;
}

.check-list li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  content: '\f00c';
  margin-right: 5px;
  padding: 2px;
  background: #ffffff;
  border: 2px solid #ff0000;
  font-weight: 900;
  color: #ff0000;
}

a.link-btn {
  display: block;
  position: relative;
  margin: 0 auto 50px;
  padding: 15px 0;
  width: 250px;
  background: #1f518e;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #ffffff;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

a.link-btn:before {
  content: "";
  bottom: 3px;
  right: 3px;
  border-top: 15px solid transparent;
  border-right: 15px solid #ffffff;
  position: absolute;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  a.link-btn {
    margin-bottom: 30px;
  }
}

.business-trouble {
  margin: 0 auto 50px;
  text-align: center;
}

.business-trouble img {
  width: 100%;
  max-width: 822px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .business-trouble {
    margin-bottom: 30px;
  }
}

.business-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .business-block {
    display: block;
  }
}

.business-left {
  width: 55%;
}

@media screen and (max-width: 768px) {
  .business-left {
    margin-bottom: 30px;
    width: 100%;
  }
}

.business-right {
  width: 40%;
}

.business-right img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .business-right {
    width: 100%;
  }
}

.proprietorship-msg {
  margin-bottom: 50px;
}

.proprietorship-msg .page-title02 {
  border-bottom: 1px solid #1f518e;
}

@media screen and (max-width: 768px) {
  .proprietorship-msg {
    margin-bottom: 30px;
  }
}

.proprietorship-bg {
  margin-bottom: 30px;
  padding: 15px 0;
  background: #e2c55f;
  text-align: center;
}

.proprietorship-title {
  font-size: 38px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  color: #333333;
}

@media screen and (max-width: 768px) {
  .proprietorship-title {
    font-size: 24px;
  }
}

.assist {
  margin: 0 auto 50px;
  text-align: center;
}

.assist img {
  width: 100%;
  max-width: 602px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .assist {
    margin-bottom: 30px;
  }
}

.assist-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .assist-block {
    display: block;
  }
}

.assist-block .check-list li {
  margin-bottom: 15px;
  padding-left: 58px;
  text-indent: -58px;
  line-height: 1.3;
}

.assist-block .check-list li:before {
  content: none;
}

@media screen and (max-width: 768px) {
  .assist-block .check-list li {
    margin-bottom: 20px;
  }
}

.assist-block .check-list span {
  margin: 0 6px 15px 0;
  padding: 4px 6px;
  background: #ead130;
  font-weight: 700;
  font-family: " メイリオ",Meiryo;
  color: #1f518e;
}

@media screen and (max-width: 768px) {
  .assist-block .check-list span {
    display: block;
    margin-bottom: 5px;
  }
}

.assist-left {
  width: 55%;
}

@media screen and (max-width: 768px) {
  .assist-left {
    margin-bottom: 30px;
    width: 100%;
  }
  .assist-left .check-list li {
    padding-left: 0;
    text-indent: unset;
  }
}

.assist-right {
  width: 40%;
}

.assist-right img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .assist-right {
    width: 100%;
  }
}

.other-msg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.other-msg .page-title02 {
  border-bottom: 1px solid #1f518e;
}

@media screen and (max-width: 768px) {
  .other-msg {
    margin-bottom: 30px;
  }
}

.other-bg {
  padding: 30px 0;
  background: url(../images/blue-bg.png) no-repeat;
  baseline-size: cover;
}

.other-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.other-list li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 200px;
  background: #ffffff;
}

.other-list li a {
  font-size: 30px;
  color: #333333;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .other-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .other-list li {
    margin-bottom: 10px;
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .other-list {
    margin-bottom: 30px;
  }
  .other-list li {
    width: 100%;
  }
}

.solicitation-policy > section {
  margin-bottom: 30px;
}

.solicitation-policy ul {
  list-style-position: inside;
}

.privacy-block {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #333333;
}

.privacy-block p, .privacy-block ol {
  margin-bottom: 20px;
}

.privacy-block dl {
  margin-bottom: 30px;
}

.privacy-block dl:last-child {
  margin-bottom: 0;
}

.privacy-block dt {
  font-weight: 700;
}

.privacy-block ol {
  list-style-position: inside;
}

.privacy-contact-block {
  border: 1px solid #122e50;
}

.privacy-contact-title {
  padding: 20px 0;
  background: #122e50;
  font-size: 20px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .privacy-contact-title {
    padding: 10px 0;
    font-size: 18px;
  }
}

.privacy-contact-text {
  padding: 20px 10px;
  text-align: center;
}

.privacy-contact-text ul {
  list-style-type: none;
}

.gmap {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.gmap iframe,
.gmap object,
.gmap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a.gmap-btn {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 6px 0;
  max-width: 243px;
  background: #2a4572;
  background-image: url(../images/icon-target-blank.png);
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.map-arrow {
  position: relative;
  display: inline-block;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.map-arrow::before, .map-arrow::after {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.map-arrow:before {
  left: 0;
  bottom: 5px;
  width: calc( 100% - 20px);
  height: 2px;
  background: #ffffff;
}

.map-arrow:after {
  top: 25px;
  right: 9px;
  width: 10px;
  height: 4px;
  border-top: 2px solid #ffffff;
  transform: rotate(45deg);
}

.affiliates .gmap-btn {
  margin-right: 0;
}

.company-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.company-block .company-info {
  width: 50%;
  height: auto;
}

.company-block .company-info th {
  border-bottom: 1px solid #ffffff;
  padding: 10px;
  width: 120px;
  background: #1f518e;
  color: #ffffff;
}

.company-block .company-info th.last {
  border-bottom: 1px solid #1f518e;
}

.company-block .company-info td {
  border: 1px solid #1f518e;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .company-block .company-info {
    margin-bottom: 30px;
    width: 100%;
  }
}

.company-block .gmap {
  width: 45%;
  padding-bottom: 26.25%;
}

.company-block .gmap iframe,
.company-block .gmap object,
.company-block .gmap embed {
  height: 100%;
}

@media screen and (max-width: 768px) {
  .company-block {
    display: block;
  }
  .company-block .gmap {
    width: 100%;
    padding-bottom: 56.25%;
  }
}

.organization-info {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
}

.organization-info th {
  border-bottom: 1px solid #ffffff;
  padding: 10px;
  width: 120px;
  background: #1f518e;
  color: #ffffff;
}

.organization-info th.last {
  border-bottom: 1px solid #1f518e;
}

.organization-info td {
  border: 1px solid #1f518e;
  padding: 10px;
}

.contact-mv .content-inner {
  padding: 30px 10px;
}

.contact-mv-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'Noto Sans JP';
  font-weight: 700;
}

.contact-mv-text {
  margin-bottom: 20px;
}

.contact-mv-list {
  display: flex;
  margin-bottom: 20px;
  list-style-type: none;
}

.contact-mv-list li {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .contact-mv-list {
    justify-content: space-between;
  }
  .contact-mv-list li {
    width: 48%;
    margin-right: 0;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .contact-mv-list {
    display: block;
  }
  .contact-mv-list li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.contact-mv-type {
  background: #1f518e;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contact-mv-type {
    font-size: 16px;
  }
}

.contact-mv-number {
  padding: 5px 20px;
  background-color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #333333;
  vertical-align: baseline;
  text-decoration: none;
}

.contact-mv-number a {
  color: #333333;
  text-decoration: none;
}

.contact-mv-number img {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .contact-mv-number img {
    width: 30px;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .contact-mv-number {
    font-size: 18px;
  }
}

.contact-data-block {
  display: flex;
  margin-bottom: 10px;
}

.contact-data-title {
  padding: 3px 5px;
  width: 90px;
  background: #333333;
  color: #ffffff;
  text-align: center;
}

.contact-data-text {
  padding: 3px 15px;
  width: 210px;
  background: #ffffff;
  font-weight: 700;
}

p .required {
  margin-right: 5px;
  padding: 2px 3px;
  background: #ff778f;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
}

.contact-form {
  width: 100%;
  height: auto;
}

.contact-form tr:last-child {
  border-bottom: 1px solid #1f518e;
}

.contact-form th {
  background: #cccccc;
  padding: 10px;
  width: 300px;
  height: auto;
  color: #333333;
  border-bottom: 1px solid #ffffff;
  text-align: left;
}

.contact-form th .required {
  float: right;
  margin-left: 10px;
  padding: 2px 3px;
  background: #ff778f;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
}

.contact-form th .any {
  float: right;
  margin-left: 10px;
  padding: 2px 3px;
  background: #2b6dd3;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
}

.contact-form td {
  border-bottom: 1px solid #ffffff;
  background: #f9f9f9;
  padding: 10px;
  width: calc( 100% - 20px);
}

.contact-form td input[type="text"], .contact-form td textarea {
  background: #f9f9f9;
  padding: 3px;
  border: 0;
  width: 100%;
  font-size: 14px;
}

.contact-form td input[type="radio"] {
  vertical-align: text-top;
}

@media screen and (max-width: 768px) {
  .contact-form th {
    display: block;
    width: calc( 100% - 20px);
    border-bottom: 0;
    text-align: left;
  }
  .contact-form td {
    display: block;
    width: calc( 100% - 20px);
  }
  .contact-form td .required, .contact-form td .any {
    float: none;
  }
}

.contact-form .privacy-block {
  height: 200px;
  overflow-y: scroll;
}

.formBtn {
  text-align: center;
}

.formBtn button {
  width: 240px;
  margin: 0 auto 20px;
  padding: 10px 30px;
  background: #1f518e;
  font-size: 20px;
  color: #ffffff;
}

.d-flex {
  display: flex;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.fz40 {
  font-size: 40px;
}

.font-bold {
  font-weight: 700;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

@media print, screen and (max-width: 768px) {
  .smb30 {
    margin-bottom: 30px !important;
  }
}

/*-------------------------------------------
スマホ表示有無
-------------------------------------------*/
@media screen and (max-width: 992px) {
  .visible-desktop {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .visible-tablet {
    display: none;
  }
}

@media print, screen and (min-width: 769px) {
  .visible-phone {
    display: none;
  }
}
