.d-flex{
  display:flex;
}

.img-fluid{
  max-width: 100%;
  height: auto;
}

.space-between{
  justify-content: space-between;
}

.align-center{
  align-items: center;
}

.flex-end{
  justify-content: flex-end;
}

.text-center{
  text-align: center;
}

.fz40{
  font-size: 40px;
}

.font-bold{
  font-weight: 700;
}

.mb30{
  margin-bottom: 30px!important;
}

.mb50{
  margin-bottom: 50px!important;
}

.mb55{
  margin-bottom: 55px!important;
}

.mb100{
  margin-bottom: 100px!important;
}

@media print,screen and (max-width: 768px) {
  .smb30{
    margin-bottom: 30px!important;
  }
}

/*-------------------------------------------
スマホ表示有無
-------------------------------------------*/
@media screen and (max-width: 992px) {
  .visible-desktop {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .visible-tablet {
    display: none;
  }
}

@media print,screen and (min-width: 769px) {
  .visible-phone {
    display: none;
  }
}