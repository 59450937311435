.item_list_contact{
  position: fixed;
  right: 0;
  bottom: 130px;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: auto;
  padding:10px 0;
  background: #1f518e;
  text-align: center;
  a{
    display: block;
    font-size: 15px;
    font-weight: 700;
    font-family:'Noto Sans JP';
    color: #ffffff;
    text-decoration: none;
    line-height: 1.3;
  }
}

.page-link-list{
  display:flex;
  justify-content: space-between;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    display: block;
  }
  a{
    font-size: 16px;
    font-weight: 700;
    font-family:'Noto Sans JP';
    color: #333333;
    text-decoration: none;
  }
  .corporation-list{
    position: relative;
    width: 45%;
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
      width: 100%;
    }
    a{
      color:#4983cf;
    }
    .corporation-list-title{
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 15px;
      background: #ffffff;
      font-size: 24px;
      color: #333333;
      span{
        color:#4983cf;
      }
      @media screen and (max-width: 768px) {
        top: -10px;
        font-size: 18px;
      }
    }
    ul{
      display:flex;
      justify-content: space-around;
      padding:20px;
      border: 2px solid #cccccc;
      list-style-type: none;
      @media screen and (max-width: 560px) {
        display: block;
      }
    }

  }
  .proprietorship-list{
    position: relative;
    width: 45%;
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
      width: 100%;
    }
    a{
      color:#e0610d;
    }
    .proprietorship-list-title{
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 15px;
      background: #ffffff;
      font-size: 24px;
      color: #333333;
      span{
        color:#e0610d;
      }
      @media screen and (max-width: 768px) {
        top: -10px;
        font-size: 18px;
      }
    }
    ul{
      display:flex;
      justify-content: space-around;
      padding:20px;
      border: 2px solid #cccccc;
      list-style-type: none;
      @media screen and (max-width: 560px) {
        display: block;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.corporation-msg{
  display:flex;
  justify-content: space-between;
  margin-bottom: 50px;
  .page-title02{
    border-bottom: 1px solid #1f518e;
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 30px;
  }
}

.corporation-msg-left{
  width: 65%;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    width: 100%;
  }
}

.corporation-msg-right{
  width: 30%;
  height: auto;
  background: #cccccc;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.item_list_box{
  padding: 20px;
  &.blue{
    background-color: #eff3f9;
  }
  &.yellow{
    background-color: #ead130;
  }
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.item_list_box2{
  border: 2px solid #1f518e;
  .item_list_inner{
    padding: 20px;
  }
  .item_list_title{
    padding-left: 20px;
    background-color: #1f518e;
    color: #ffffff;
    .page-title03{
      color: #ffffff;
    }
  }
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.item_list_box3{
  border: 2px solid #ead130;
  .item_list_inner{
    padding: 20px;
  }
  .item_list_title{
    padding-left: 20px;
    background-color: #ead130;
    color: #ffffff;
    .page-title03{
      color: #ffffff;
    }
  }
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.corporation-bg{
  margin-bottom: 30px;
  padding: 15px 0;
  background: #ccd5e1;
  text-align: center;
}

.corporation-title{
  font-size: 38px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  color: #0065b8;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.cyber-image-list{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 100px;
  list-style-type: none;
  li{
    width: 30%;
    img{
      max-width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 992px) {
    li{
      width: 30%;
    }
  }
  @media screen and (max-width: 768px) {
    li{
      width: 48%;
    }
  }
}

.if-title{
  display:inline-block;
  padding: 0 30px;
  border-bottom: 2px solid #1f518e;
  margin: 0 auto 50px;
  font-size: 30px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 15px;
  }
}

.corporation-arrow{
  margin-bottom: 70px;
  position: relative;
  width: 70px;
  height: 101px;
  border-right: 70px solid #0065b8;
  box-sizing: border-box;
  margin: 0 auto;
  &::after{
    content: "";
    position: absolute;
    bottom: -70px;
    right: -102px;
    border-top: 70px solid #0065b8;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
  }
}

.cyber-lead{
  margin-bottom: 50px;
  padding: 20px;
  background: #ff0000;
  font-size: 40px;
  font-weight: 700;
  color:#ffffff;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 28px;
  }
  @media screen and (max-width: 640px) {
    padding: 10px;
    font-size: 24px;
  }
}

.cyber-block{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.cyber-left{
  width: 65%;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    width: 100%;
  }
}

.cyber-right{
  width: 30%;
  img{
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.check-list{
  list-style-type:none;
  list-style-position: outside;
  li{
    margin-bottom: 5px;
    padding-left: 26px;
    text-indent: -26px;
    &:before{
      content:"";
      font-family: "Font Awesome 5 Free";
      content: '\f00c';
      margin-right: 5px;
      padding: 2px;
      background: #ffffff;
      border: 2px solid #ff0000;
      font-weight: 900;
      color: #ff0000;
    }
  }
}

a.link-btn{
  display: block;
  position: relative;
  margin: 0 auto 50px;
  padding: 15px 0;
  width: 250px;
  background:#1f518e;
  font-size: 16px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  color: #ffffff;
  text-align:center;
  text-decoration: underline;
  cursor: pointer;
  &:before {
    content: "";
    bottom: 3px;
    right: 3px;
    border-top: 15px solid transparent;
    border-right: 15px solid #ffffff;
    position: absolute;
    z-index: 100;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.business-trouble{
  margin: 0 auto 50px;
  text-align: center;
  img{
    width: 100%;
    max-width: 822px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.business-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.business-left{
  width: 55%;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    width: 100%;
  }
}

.business-right{
  width: 40%;
  img{
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.proprietorship-msg{
  margin-bottom: 50px;
  .page-title02{
    border-bottom: 1px solid #1f518e;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.proprietorship-bg{
  margin-bottom: 30px;
  padding: 15px 0;
  background: #e2c55f;
  text-align: center;
}

.proprietorship-title{
  font-size: 38px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  color: #333333;
  @media screen and (max-width: 768px) {;
    font-size: 24px;
  }
}

.assist{
  margin: 0 auto 50px;
  text-align: center;
  img{
    width: 100%;
    max-width: 602px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.assist-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    display:block;
  }
  .check-list{
    li{
      margin-bottom: 15px;
      padding-left: 58px;
      text-indent: -58px;
      line-height: 1.3;
      &:before{
        content:none;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    span{
      margin: 0 6px 15px 0;
      padding: 4px 6px;
      background: #ead130;
      font-weight: 700;
      font-family: " メイリオ",Meiryo;
      color: #1f518e;
      @media screen and (max-width: 768px) {
        display:block;
        margin-bottom: 5px;
      }
    }
  }
}

.assist-left{
  width: 55%;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    width: 100%;
    .check-list{
      li{
        padding-left: 0;
        text-indent: unset;
      }
    }
  }
}

.assist-right{
  width: 40%;
  img{
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.other-msg{
  display:flex;
  justify-content: space-between;
  margin-bottom: 50px;
  .page-title02{
    border-bottom: 1px solid #1f518e;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.other-bg{
  padding: 30px 0;
  background:url(../images/blue-bg.png)no-repeat;
  baseline-size: cover;
}

.other-list{
  display:flex;
  justify-content: space-between;
  margin-bottom: 50px;
  li{
    display: flex;
    justify-content: center;
    align-items: center;
    width:20%;
    height:200px;
    background: #ffffff;
    a{
      font-size: 30px;
      color: #333333;
      text-align: center;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: space-around;
    li{
      margin-bottom: 10px;
      width: 40%;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    li{
      width: 100%;
    }
  }
}