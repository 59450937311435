.page-content{
  padding: 50px 0;
  font-family: "メイリオ",Meiryo;
}

.page-title {
  margin-bottom: 30px;
  border-bottom: solid 3px #cccccc;
  position: relative;
  font-size: 30px;
  font-weight: 700;
  font-family:'Noto Sans JP';
  &:after {
    position: absolute;
    content: " ";
    display: block;
    border-bottom: solid 3px #1f518e;
    bottom: -3px;
    width: 30%;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.page-title02{
  margin-bottom: 10px;
  font-size: 28px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.page-title03{
  margin-bottom: 10px;
  font-size: 20px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  color: #1f518e;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}