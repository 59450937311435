a:hover{
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  img{
    max-width: 100%;
    height: auto;
  }
}

@import "_layout";
@import "_header";
@import "_footer";
@import "_home";
@import "_mv";
@import "_top";
@import "_page";
@import "_item-list1";
@import "_item-list2";
@import "_organization";
@import "_contact";
@import "_utility";
