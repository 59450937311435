.mv{
  &.home{
    background: url(../images/mv.jpg)no-repeat;
    background-size: cover;
  }
  &.top-mv{
    background: url(../images/top/top-mv.jpg)no-repeat center center;
    background-size: cover;
  }
  &.item-list1-mv,&.item-list2-mv,&.organization-mv{
    background: url(../images/page-mv.jpg)no-repeat center center;
    background-size: cover;
  }
  &.contact-mv{
    background: url(../images/contact/contact-mv.jpg)no-repeat center center;
    background-size: cover;
  }
}

.header-copy{
  text-align: center;
  padding: 240px 0;
  @media screen and (max-width: 992px) {
    padding: 100px 0;
  }
}

.page-mv-copy{
  text-align: center;
  padding: 100px 0;
  font-size: 38px;
  font-family:'Noto Sans JP';
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0.1em;
  .corporation{
    display:inline-block;
    color:#4983cf;
    vertical-align: top;
  }
  .proprietorship{
    display:inline-block;
    color:#e0610d;
    vertical-align: top;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
}